<template>
  <RevCardCarousel
    :id="textToTarget(title, 'heading-2')"
    :desktop-card-width="carouselWidth"
    :next-alternative-text="altText.next"
    :prev-alternative-text="altText.prev"
    :size
    :subtitle
    :title
  >
    <ModularCard
      v-for="(card, index) of cards"
      :key="card.id"
      :background="card.background"
      :blockPosition
      :card-position="index + 1"
      class="h-full"
      :link="card.link"
      :sections="card.sections"
      :size
      :technical-name="card.technicalName"
      :tracking
      :with-padding="cardsHavePadding"
    />

    <template v-if="ctaLink" #button>
      <RevButton
        :icon="IconArrowRight"
        :rel="ctaLink?.link?.rel"
        size="medium"
        :target="ctaLink?.link?.target"
        :to="ctaLink?.link?.href"
        variant="primary"
      >
        {{ ctaLink?.label }}
      </RevButton>
    </template>
  </RevCardCarousel>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type ModularCardsCarouselProps } from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { textToTarget } from '@backmarket/nuxt-layer-cms/utils/textToTarget'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { IconArrowRight } from '@ds/icons/IconArrowRight'

import translations from '../../common/translations'

import ModularCard from './ModularCard.vue'

const props = withDefaults(
  defineProps<Omit<ModularCardsCarouselProps, 'layout'> & ContentBlockProps>(),
  {
    cardsHavePadding: true,
  },
)

const i18n = useI18n()

const altText = {
  prev: i18n(translations.alternativePrevious),
  next: i18n(translations.alternativeNext),
}

const carouselWidth = computed(() =>
  props.size === 'medium' ? 'wide' : 'standard',
)
</script>
